import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AdnAchievement, AdnTarget, ComplianceMetrics, managementItem, VariableDirect } from '@shared/interfaces/frontend/adn';
import {variablesDirectGestionJson} from '@shared/data/adn/variables.json';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalInformativeComponent } from '../modal-informative/modal-informative.component';
import { ModalDisbursementComponent } from '../modal-disbursement/modal-disbursement.component';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { calculateDirectRemuneration, 
         calculateWeightedCompliance, 
         convertToNumeric, 
         formatNumberWithCommasNoDecimals, 
         formatPercentage, 
         formatPercentageV2 } from '@shared/utils/adn-simulator.utils';
import { INCREASE } from '@shared/constants/adn-simulator.constants';

@Component({
  selector: 'milab-section-direct-variables',
  templateUrl: './section-direct-variables.component.html',
  styleUrls: ['./section-direct-variables.component.scss']
})
export class SectionDirectVariablesComponent implements OnInit, OnChanges{
  modalRef!: NgbModalRef;
  numberValue: string = '';
  variablesDirectGestion: any[] = [];
  headers: string[] = [];
  isTable: boolean = false;
  isRotated: boolean = false;
  isVarComercer: boolean = true;

  amount:number = 0;

  targets: AdnTarget[] = [];
  achievements: AdnAchievement[] = [];

  @Input() managementVarCommercial!: managementItem;
  @Input() managementVarCollectionResolution!: managementItem;
  @Input() managementVarCollectionPise!: managementItem;

  @Input() listVariable: VariableDirect[] = [];
  @Input() listCollectionVariablesPise9: VariableDirect[] = [];
  @Input() listCollectionVariablesResolutions: VariableDirect[] = [];

  @Input() directRemunerationValue: number = 0;
  @Input() weightedComplianceInitial: number = 0;
  @Input() weightedComplianceComercialInitial: number = 0;
  @Input() weightedComplianceCobranzaInitial: number = 0;
  @Input() amountDistributed: number = 0;
  @Input() pasivosMeta:any;

  weightedCompliance: number = 0;
  directRemunerationInitial: number = 0;
  weightedComplianceString: string = '0';
  imgComplianceWeight: string = '/assets/icons/adn/x-close.svg';

  gestionPreciosList: VariableDirect[] = [];

  increaseCompliance: string ='';
  increaseDistributed: string ='';
  complianceColor: string = INCREASE.colorIncrement;
  distributedColor: string = INCREASE.colorIncrement;
  iconCompliance: string =INCREASE.iconArrowUp;
  iconDistributede: string =INCREASE.iconArrowUp;
  flagActivateRV: boolean = false;
  
  constructor(private modalService: NgbModal,private adnSimulatorService:AdnSimulatorService) { 
    this.adnSimulatorService.metrics$.subscribe((value) => {
      const ponderado = this.calculateWeightedComplianceSync(value);
      calculateWeightedCompliance(ponderado)
        ? (this.imgComplianceWeight = '/assets/icons/adn/check-circle.svg')
        : (this.imgComplianceWeight = '/assets/icons/adn/x-close.svg');
      this.updateDirectRemunerationValue(ponderado);
    });
  }
  ngOnInit(): void {
    this.loadVariables();
    this.gestionPreciosList = this.listVariable.filter(variable => variable.id != 'gestionPrecios');
    this.calculateInit();
  }
  calculateInit(){
    const adjustedValue = calculateWeightedCompliance(this.weightedComplianceInitial);
    this.directRemunerationInitial = calculateDirectRemuneration(this.amountDistributed, this.weightedComplianceInitial, adjustedValue);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.listVariable) {
      this.gestionPreciosList = this.listVariable.filter(variable => variable.id != 'gestionPrecios');
    }
  }
  loadVariables(): void {
    this.variablesDirectGestion = variablesDirectGestionJson;
  }

  newAmount(){
   this.openModalDisbursement();
  }
  openModal() {
    this.modalRef = this.modalService.open(ModalInformativeComponent, { windowClass: 'modal-informative' });
    this.modalRef.result.then((on) => {
    }).catch((error) => {
      console.log(error);
    });
  }

  openModalDisbursement(){
    this.modalRef = this.modalService.open(ModalDisbursementComponent, { windowClass: 'modal-disbursement' });
    this.modalRef.result.then((on) => {
      if(on){
        this.amount = on;
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  tabOptions(event: string): void {
    if(event === 'Var. Comerciales'){
      this.isVarComercer = true;
  }else{
    this.isVarComercer = false;
  }
}

  activeTable(){
    this.isTable = !this.isTable;
    this.isRotated = !this.isRotated;
  }

  private updateDirectRemunerationValue(weightedCompliance: number): void {
    this.weightedCompliance = convertToNumeric(formatPercentageV2(weightedCompliance));
    this.calculeIncremented(weightedCompliance);
      const adjustedValue = calculateWeightedCompliance(weightedCompliance);
      this.directRemunerationValue = calculateDirectRemuneration(this.amountDistributed, weightedCompliance, adjustedValue);
      this.calculeIncrementedRemuneration(this.directRemunerationValue);
    }

  calculeIncremented(weightedCompliance: number): void {
    const increaseTemp = (weightedCompliance-this.weightedComplianceInitial);
    if (increaseTemp > 0) {
      this.increaseCompliance = formatPercentage(increaseTemp);
      this.complianceColor = INCREASE.colorIncrement;
      this.iconCompliance = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseCompliance = formatPercentage(increaseTemp);
      this.complianceColor = INCREASE.colorDecrement;
      this.iconCompliance = INCREASE.iconArrowDown;
    } else {
      this.increaseCompliance = '';
    }
  }

  calculeIncrementedRemuneration(remunerationValue:number): void {
    const increaseTemp = (remunerationValue-this.directRemunerationInitial);
    const increaseTempTwoDecimals = parseFloat(increaseTemp.toFixed(2));
    if (increaseTemp > 0) {
      this.increaseDistributed = 'S/ '+formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColor = INCREASE.colorIncrement;
      this.iconDistributede = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseDistributed = 'S/ '+formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColor = INCREASE.colorDecrement;
      this.iconDistributede = INCREASE.iconArrowDown;
    } else {
      this.increaseDistributed = '';
    }
  }

  calculateWeightedComplianceSync(metrics: ComplianceMetrics): number {
    return metrics.complianceNetDisbursement.compliance*(metrics.complianceNetDisbursement.weight )+
    metrics.complianceActiveClientStock.compliance*(metrics.complianceActiveClientStock.weight)+
    metrics.complianceLiabilitiesCapture.compliance*(metrics.complianceLiabilitiesCapture.weight )+
    metrics.compliancePriceManagement.compliance*(metrics.compliancePriceManagement.weight )+
    metrics.compliancePise9Mora.compliance*(metrics.compliancePise9Mora.weight)+
    metrics.complianceResolution0Days.compliance*(metrics.complianceResolution0Days.weight);
  }

  handleFlagActivateRV(value: boolean): void {
    this.flagActivateRV = value;
  }
}