<section class="bonus">
  <h2 class="bonus__title">Bonificación 0-20K</h2>
  <div class="row">
    <div class="col-12 col-md-7">
      <!-- MOBILE -->
      <div class="recover card d-block d-md-none">
        <div class="recover__header t-16-700">
          Pase a Recuperaciones PE* (S/)
        </div>
        <div class="row align-items-center my-3">
          <div class="col-6 t-14-400">Logro:</div>
          <div class="col-6 text-right">
            <input
              class="recover__input t-14-700 text-right"
              type="text"
              [value]="achievement"
              (input)="setAchievement($event, true)"
              (blur)="onBlurSetAchievement()"
              milabNumericmask
            />
          </div>
        </div>
        <div class="row color-primary">
          <div class="col-6 t-14-400">Cumplimiento:</div>
          <div class="col-6 t-16-700 text-right" style="color: #00B33C;">{{ compliance.toFixed(2) }}%
            <div class="increase-wrapper" *ngIf="variable.increase && variable.increase.length > 0">
              <milab-increase-card
                [increase]="variable.increase"
                [icon]="variable.increaseIcon"
                [color]="variable.increaseColor">
              </milab-increase-card>
            </div>
          </div>
        </div>
        <div class="goals">
          <p class="t-16-700 my-3">Metas a lograr</p>
          <div class="row goals__cnt roundTop">
            <div class="col-6 goals__cnt-first" style="color: #fff;">
              <p class="t-14-700">80%</p>
            </div>
            <div class="col-6 goals__cnt-second">
              <p class="t-14-400">{{(dataBonus.bonus20k * 0.8) | amountNumericFormatter}}</p>
            </div>
          </div>
          <div class="row goals__cnt roundBot">
            <div class="col-6 goals__cnt-first" style="color: #fff;">
              <p class="t-14-700">100%</p>
            </div>
            <div class="col-6 goals__cnt-second">
              <p class="t-14-400">{{dataBonus.bonus20k | amountNumericFormatter}}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- ****************** -->
      <!-- DESKTOP -->
      <div class="recover card d-none d-md-block">
        <div class="row recover__header t-16-700">
          <div class="col-3"></div>
          <div class="col-3 t-14-700 text-center">Logro</div>
          <div class="col-3 t-14-700 text-center">Cumplimiento</div>
          <div class="col-3 t-14-700 text-center">Meta</div>
        </div>
        <div class="row recover__body t-16-700">
          <div class="col-3 t-14-700 p-0">
            Desembolsos 0-20 K
          </div>
          <div class="col-3 ">
            <input
              class="recover__input t-14-700 text-right"
              type="text"
              [value]="achievement"
              (input)="setAchievement($event, true)"
              (blur)="onBlurSetAchievement()"
              milabNumericmask
            />
          </div>
          <div class="col-3 d-flex flex-column">
            <p class="t-14-700 text-center" style="color: #00B33C;">{{ compliance.toFixed(2) }}%</p>
            <div class="increase-wrapper mx-auto" *ngIf="variable.increase && variable.increase.length > 0">
              <milab-increase-card
                [increase]="variable.increase"
                [icon]="variable.increaseIcon"
                [color]="variable.increaseColor"
              >
              </milab-increase-card>
            </div>
          </div>
          <div class="col-3 p-0">
            <div class="goals">
              <div class="row goals__cnt roundTop">
                <div class="col-12 goals__cnt-first" style="color: #fff;">
                  <p class="t-14-700">80%</p>
                </div>
                <div class="col-12 goals__cnt-second">
                  <p class="t-14-400">{{(dataBonus.bonus20k * 0.8) | amountNumericFormatter}}</p>
                </div>
              </div>
              <div class="row goals__cnt roundBot">
                <div class="col-12 goals__cnt-first" style="color: #fff;">
                  <p class="t-14-700">100%</p>
                </div>
                <div class="col-12 goals__cnt-second">
                  <p class="t-14-400">{{dataBonus.bonus20k | amountNumericFormatter}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ****************** -->
    </div>
    <div class="col-12 col-md-5">
      <div class="disbursement card">
        <p class="t-14-400">Bonificación por Desembolsos 0 -20 K</p>
        <h2 class="t-24-700">S/ {{amountBonus | amountNumericFormatter}} 
          <div class="increase-wrapper" *ngIf="amountVariable.increase && amountVariable.increase.length > 0">
            <milab-increase-card
              [increase]="amountVariable.increase"
              [icon]="amountVariable.increaseIcon"
              [color]="amountVariable.increaseColor">
            </milab-increase-card>
          </div>
        </h2>
        <p class="t-12-400">Cumplimiento RV: <strong class="t-14-700">{{validCompliance.txt}}</strong> <img class="mb-1 ml-1" [src]="validCompliance.img" width="16" height="16" alt=""></p>
        <p class="t-12-400">Desembolsos 0-20K ≥ 90%: <strong class="t-14-700">{{validBonus.txt}}</strong> <img class="mb-1 ml-1" [src]="validBonus.img" width="16" height="16" alt=""></p>
      </div>
    </div>
  </div>
</section>