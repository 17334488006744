import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComplianceMetrics } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { calculateBonus, calculateDivisionInput, calculateWeightedCompliance, 
         complianceColorValid, 
         convertToNumeric, 
         division, 
         formatNumber, 
         formatPercentageV2, 
         truncateToTwoDecimals} from '@shared/utils/adn-simulator.utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'milab-modal-summary',
  templateUrl: './modal-summary.component.html',
  styleUrls: ['./modal-summary.component.scss']
})
export class ModalSummaryComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  
  @Input() directVariableAmount: number = 0;
  @Input() acceleratorPercentage: number = 0;
  @Input() deceleratorPercentage: number = 0;
  @Input() finalRv: number = 0;
  @Input() finalTotal: number = 0;
  @Input() meta20k: number = 0;

  metrics!: ComplianceMetrics;
  dataComercial:any;
  dataCobranza:any;
  weightedCompliance: number = 0;

  bonusO20k: number = 0;
  factorAucencia: number = 1;
  directVariableAmountString: string = '0';
  finalRvString: string = '0';
  finalTotalString: string = '0';
  imgComplianceWeight: string = '/assets/icons/adn/x-close.svg';
  bgColorSummary: string = "bg-notok-summary";

  increaseAmount:string = '';
  increaseAmountColor:string = 'color-numeric';
  increaseAmountIcon:string = '/assets/icons/adn/plus-blue.svg';

  advanceTemp: number = 0;
  valueString: string = '0';
  constructor(public activeModal: NgbActiveModal, private adnSimulatorService:AdnSimulatorService) { }

  ngOnInit(): void {
    this.subscribeSumary();
    this.getBonus();
  }

  subscribeSumary(){
    this.subscription.add(
      this.adnSimulatorService.metrics$.subscribe((metrics) => {
        this.metrics = metrics;
        this.updateData(this.metrics);
      })
    );
  }

  updateData(metrics: ComplianceMetrics): void {
  

    this.dataComercial = [
      { name: 'Desembolso Neto', value: metrics.complianceNetDisbursement.compliance, weight: (metrics.complianceNetDisbursement.weight * 100).toString(),color:complianceColorValid(metrics.complianceNetDisbursement.compliance) },
      { name: 'Stock de Clientes Activos', value: metrics.complianceActiveClientStock.compliance, weight: (metrics.complianceActiveClientStock.weight * 100).toString(),color:complianceColorValid(metrics.complianceActiveClientStock.compliance) },
      { name: 'Captación de Pasivos', value: metrics.complianceLiabilitiesCapture.compliance, weight: (metrics.complianceLiabilitiesCapture.weight * 100).toString(),color:complianceColorValid(metrics.complianceLiabilitiesCapture.compliance)},
      { name: 'Gestión de Precios', value: metrics.compliancePriceManagement.compliance, weight: (metrics.compliancePriceManagement.weight * 100).toString(),color:complianceColorValid(metrics.compliancePriceManagement.compliance) }
    ];

    this.dataCobranza = [
      { name: 'Pise 9 (Mora > 8)', value: metrics.compliancePise9Mora.compliance, weight: (metrics.compliancePise9Mora.weight * 100).toString() ,color:complianceColorValid(metrics.compliancePise9Mora.compliance)},
      { name: 'Resolución 0 días', value: metrics.complianceResolution0Days.compliance, weight: (metrics.complianceResolution0Days.weight * 100).toString() ,color:complianceColorValid(metrics.complianceResolution0Days.compliance)}
    ];

    this.weightedCompliance = convertToNumeric(formatPercentageV2(this.calculateWeightedCompliance(metrics)));

    const adjustedValue = calculateWeightedCompliance(this.weightedCompliance)
    adjustedValue ? (this.imgComplianceWeight = '/assets/icons/adn/check-circle.svg')
    : (this.imgComplianceWeight = '/assets/icons/adn/x-close.svg');
    adjustedValue ? (this.bgColorSummary = "bg-ok-summary"):
    (this.bgColorSummary = "bg-notok-summary");
    this.directVariableAmountString = formatNumber(this.directVariableAmount.toString());
    this.finalRvString = formatNumber(this.finalRv.toFixed(2));
    this.finalTotalString = formatNumber((this.finalRv + this.bonusO20k).toFixed(2));
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

    getBonus() {
      this.adnSimulatorService.amount20kSubject$.subscribe((data) => {
        this.advanceTemp =  calculateDivisionInput(data,this.meta20k);  
        const compliance = this.weightedCompliance>70?1:0;
        const avance = this.advanceTemp>=80?1:0;
        const divisionTemp = division(data,this.meta20k)*100;
        const bonus = calculateBonus(compliance,avance,divisionTemp);
        this.valueString = truncateToTwoDecimals(bonus);
      });
    }

  calculateWeightedCompliance(metrics: ComplianceMetrics): number {
    return metrics.complianceNetDisbursement.compliance*(metrics.complianceNetDisbursement.weight )+
    metrics.complianceActiveClientStock.compliance*(metrics.complianceActiveClientStock.weight)+
    metrics.complianceLiabilitiesCapture.compliance*(metrics.complianceLiabilitiesCapture.weight )+
    metrics.compliancePriceManagement.compliance*(metrics.compliancePriceManagement.weight )+
    metrics.compliancePise9Mora.compliance*(metrics.compliancePise9Mora.weight)+
    metrics.complianceResolution0Days.compliance*(metrics.complianceResolution0Days.weight);
  }
}
