import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { News } from '@shared/interfaces/frontend/adn';


import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Pagination, Navigation, Autoplay]);


@Component({
  selector: 'milab-modal-news',
  templateUrl: './modal-news.component.html',
  styleUrls: ['./modal-news.component.scss']
})
export class ModalNewsComponent implements OnInit {
  @Input() item!: News;
  imageList: { imageNews: string }[] = [];

  page: number =1;
  loader :boolean = true;
@ViewChild('swiperMobile', { static: false }) swiperMobile?: SwiperComponent;
  configSwiper: any = {
    pagination: {
      type: 'custom',
    },
    spaceBetween: 0,
    initialSlide: 0,
    centeredSlides: true,
    roundLengths: true,
    loop: false,
    loopAdditionalSlides: 30,
    speed: 400,
    autoplay: false,
    slidesPerView: 1,
    observer: true,
  };


  constructor(public activeModal: NgbActiveModal,private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.item) {
      this.imageList.push({ imageNews: this.item.imageNews });


      if (this.item.childrenNews) {
        this.imageList = [
          ...this.imageList,
          ...this.item.childrenNews.map((child) => ({
            imageNews: child.imageNews
          }))
        ];
      }
    }
  }

  onSlideChange() {
    const currentIndex = this.swiperMobile?.swiperRef.realIndex;
    this.page = (currentIndex ?? 0) + 1;
    this.cdr.detectChanges();
  }

  listNextMobile() {
    this.swiperMobile?.swiperRef.slideNext(400);
    const currentIndex = this.swiperMobile?.swiperRef.activeIndex; 
    this.page = (currentIndex ?? 0) + 1;
  }

  listPrevMobile() {
    this.swiperMobile?.swiperRef.slidePrev(400);
    const currentIndex = this.swiperMobile?.swiperRef.activeIndex; 
    this.page = (currentIndex ?? 0) + 1;
  }
}