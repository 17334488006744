<div class="md-informative">
    <div class="md-informative__header">
        <h2 class="md-informative__title">¿Cómo se calcula esto?</h2>
        <img 
          src="/assets/icons/adn/close-modal.svg" 
          alt="Cerrar" 
          class="md-informative__close-icon" 
          (click)="activeModal.close()" />
    </div>
    <div class="md-informative__body">
     <h2 class="md-informative__body_title">RV por variables directas</h2>
      <p >A partir de febrero del 2025, la Remuneración Variable (RV) se activará con un <b>50% de cumplimiento individual del frente Comercial y Cobranzas.</b></p>
      <p>La <b>RV Total será igual a la suma</b> de la RV Comercial y la RV de Cobranzas</p>
      <p>Asimismo, el Multiplicador por Pase a Recuperaciones ahora <b>afectará exclusivamente al frente 
      de Cobranzas</b>. Por otro lado, <b>el monto a repartir será proporcional</b> según el tipo de frente: Comercial 60% y Cobranzas 40%.</p>
      <p class="note"><b>Nota:</b> Cabe señalar que el cumplimiento de cada variable se calculará a partir del logro de cada variable respecto a la meta asignada.</p>
    </div>
    <div class="md-informative__footer">
      <button 
        type="button" 
        class="md-informative__footer__button t-16-700 font-lato"
        (click)="activeModal.close()">
        Entendido
      </button>
    </div>
  </div>