import { Component, HostListener, OnInit } from '@angular/core';
import { News } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { ModalNewsComponent } from '../../components/modal-news/modal-news.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { formatDateUtil } from '@shared/utils/adn-simulator.utils';
import { AdnUserProfile } from '@shared/models/UserProfileModel';
import { NewsViewed } from '@shared/interfaces/frontend/adn';
import { LocalStorageService } from '@shared/services/local-storage.service';

@Component({
  selector: 'milab-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  newsList: News[] = [];
  loader: boolean = false;
  modalRef!: NgbModalRef;

  updatedDate = '2025-01-26T22:24:03.236Z';
  user!: AdnUserProfile;
  newsState: NewsViewed[] = [];
  isMobile: boolean = window.innerWidth < 768;
  constructor(private adnSimulatorService:AdnSimulatorService,private modalService: NgbModal,private localStorageService: LocalStorageService) { }
  ngOnInit(): void {
  this.onResize();
  this.initData();
  }
  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth < 768;
  }

  initData(): void {
    const adnUserProfile = this.localStorageService.get('adnUserProfile');
    if (adnUserProfile) {
      this.user = JSON.parse(adnUserProfile) as AdnUserProfile;
      this.fetchAndTransformNews();
    }
  }

  fetchAndTransformNews(): void {
    this.adnSimulatorService.getNews(this.user.userId).subscribe((data) => {
      this.newsList = data;
      this.updateNewsState(this.newsList);
      this.updatedDate = formatDateUtil(this.getMostRecentDate());
      this.finalizarCarga();
   
    });
  }

  updateNewsState(newsList:News[]): void {
    this.newsState = newsList.map(news => ({
      newsId: news.newsId,
      viewed: news.viewed
    }));
    this.adnSimulatorService.setInitViewedNews(this.newsState);
  }

  getMostRecentDate(): string {
    if (this.newsList.length === 0) {
      return '';
    }
    const mostRecentNews = this.newsList.reduce((latest, news) => {
      return new Date(news.lastUpdated) > new Date(latest.lastUpdated) ? news : latest;
    });
    return mostRecentNews.lastUpdated;
  }

  openModal(item: News) {
    if(!item.viewed){
         this.adnSimulatorService.updateView(this.user.userId, item.newsId).subscribe(() => {
          const newsItem = this.newsList.find(news => news.newsId === item.newsId);
          if (newsItem) {
            newsItem.viewed = true;
          }
          this.updateNewsState(this.newsList);
        });
    }
    this.modalRef = this.modalService.open(ModalNewsComponent, { windowClass: 'modal-news' });
    this.modalRef.componentInstance.item = item;
  }

  private finalizarCarga(): void {
    setTimeout(() => {
      this.loader = true;
    }, 1000);
  }
}
