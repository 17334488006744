import { Component, Input, OnInit } from '@angular/core';
import { INCREASE } from '@shared/constants/adn-simulator.constants';
import { ComplianceMetrics } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import { calculateDivision, 
         calculateMultiplier, 
         denominatorDecelerator, 
         division, 
         overachievementPercentage } from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-multipliers-card',
  templateUrl: './multipliers-card.component.html',
  styleUrls: ['./multipliers-card.component.scss']
})
export class MultipliersCardComponent implements OnInit {
  @Input() title: string = 'Multiplicador';
  @Input() acceleratorPercent: number =110;
  @Input() deceleratorPercent: number=115;
  @Input() description1: string = 'Acelerador por sobrecumplimiento';
  @Input() description2: string = 'Multiplicador RV por pase a recuperaciones';
  @Input() finalAutonomy: 'sin autonomia' | 'con autonomia' = 'sin autonomia';
  @Input() amount20k: number = 0;
  @Input() dataDecelarators = {
    metaPaseRec: 0,
    passMonth2: 0,
    passMonth3: 0,
    achievementPassBalance: 0
  };
  acceleratorInit!:number;
  deceleratorInit!:number;

  increaseAccelerator: string ='';
  acceleratorColor: string = INCREASE.colorIncrement;
  iconAccelerator: string =INCREASE.iconArrowUp;

  increaseDecelerator: string ='';
  deceleratorColor: string = INCREASE.colorIncrement;
  iconDecelerator: string =INCREASE.iconArrowUp;
  constructor(private adnSimulatorService:AdnSimulatorService) { 
    this.adnSimulatorService.metrics$.subscribe((value) => {
      const ponderado = this.calculateWeightedComplianceSync(value);
      this.acceleratorPercent = overachievementPercentage(ponderado,this.finalAutonomy);
      this.adnSimulatorService.updateAcceleratorPercentage(this.acceleratorPercent);
      this.calculeIncrementedAccelerator(this.acceleratorPercent);
    });
  
  }

  ngOnInit(): void {
    this.acceleratorInit=this.acceleratorPercent;
    this.deceleratorInit=this.deceleratorPercent;
    this.getAchievementPassBalance();
  }
  getAchievementPassBalance() {
    this.adnSimulatorService.achievementPassBalanceSubject$.subscribe((achievement) => {
      const passMonth1  = division(achievement,this.dataDecelarators.metaPaseRec);
      const passSummary = (this.dataDecelarators.passMonth2+this.dataDecelarators.passMonth3 +passMonth1);
      const baseAverage = calculateDivision(passSummary,denominatorDecelerator(this.dataDecelarators.passMonth2,this.dataDecelarators.passMonth3))*100;
      this.deceleratorPercent = calculateMultiplier(baseAverage);
      this.adnSimulatorService.updateDeceleratorPercentage(this.deceleratorPercent);
     this.calculeIncrementedDecelerator(this.deceleratorPercent);
    });
  }


  calculeIncrementedAccelerator(valor: number): void {
    const increaseTemp = (valor-this.acceleratorInit);
    const increaseTempRounded = Math.round(increaseTemp);
    if (increaseTemp > 0) {
      this.increaseAccelerator = increaseTempRounded.toString()+'%';
      this.acceleratorColor = INCREASE.colorIncrement;
      this.iconAccelerator = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseAccelerator = increaseTempRounded.toString()+'%';
      this.acceleratorColor = INCREASE.colorDecrement;
      this.iconAccelerator = INCREASE.iconArrowDown;

    } else {
      this.increaseAccelerator = '';
    }
  }

  calculeIncrementedDecelerator(valor: number): void {
    const increaseTemp = (valor-this.deceleratorInit);
    const increaseTempRounded = Math.round(increaseTemp);
    if (increaseTemp > 0) {
      this.increaseDecelerator = increaseTempRounded.toString()+'%';
      this.deceleratorColor = INCREASE.colorIncrement;
      this.iconDecelerator = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseDecelerator = increaseTempRounded.toString()+'%';
      this.deceleratorColor = INCREASE.colorDecrement;
      this.iconDecelerator = INCREASE.iconArrowDown;

    } else {
      this.increaseDecelerator = '';
    }
  }
  
  calculateWeightedComplianceSync(metrics: ComplianceMetrics): number {
    return metrics.complianceNetDisbursement.compliance*(metrics.complianceNetDisbursement.weight )+
    metrics.complianceActiveClientStock.compliance*(metrics.complianceActiveClientStock.weight)+
    metrics.complianceLiabilitiesCapture.compliance*(metrics.complianceLiabilitiesCapture.weight )+
    metrics.compliancePriceManagement.compliance*(metrics.compliancePriceManagement.weight )+
    metrics.compliancePise9Mora.compliance*(metrics.compliancePise9Mora.weight)+
    metrics.complianceResolution0Days.compliance*(metrics.complianceResolution0Days.weight);
  }
}
