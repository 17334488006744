<div class="modal-general">
    <div class="modal-content modal-news">
      <swiper #swiperMobile class="swiper-container col-12 training-dashboard-banner"
      [config]="configSwiper" modules="{[Pagination,Autoplay, Navigation]}" *ngIf="imageList.length" (slideChange)="onSlideChange()">
      <ng-template swiperSlide *ngFor="let list of imageList; let i = index">
        <img [src]="list.imageNews" alt="news-image" class="modal-image" />
      </ng-template>
    </swiper>
  
    <div class=" arrow-mobile-training " *ngIf="imageList.length > 1">
        <div class=" arrow-mobile-training__container">
          <div class="training-dashboard__container_arrow mt-3">
            <img
              src="/assets/icons/arrow-next.svg"
              class="training-dashboard_arrow_left"
              (click)="listPrevMobile()"
              alt="arrow left"
            />
            <p class="training-dashboard__arrow mb-0">{{this.page}} / <span class="training-dashboard__arrow_span">{{imageList.length}}</span></p>
            <img
              src="/assets/icons/arrow-next.svg"
              class="training-dashboard_arrow_right"
              (click)="listNextMobile()"
              alt="arrow right"
            />
          </div>
        </div>
      </div>


    </div>
  </div>

  <button (click)="activeModal.close()" [ngClass]="{
    'close-btn-outside': imageList.length <= 1, 
    'close-btn-outside-multiple': imageList.length > 1
  }">
    <img src="/assets/icons/adn/close-modal.svg" alt="close" />
  </button>