<div class="simulator">
  <milab-section-info
    class="mt-3"
    [amountDistributed]="amountDistributedString"
    [workedDays]="workedDays"
    [updatedDate]="metaUpdatedDate"
  >
  </milab-section-info>

  <div class="pd-0-20">
    <div class="row mt-4">
      <div class="col-12 custom-col-3-5 d-none d-md-block custom-padding">
        <div class="d-flex justify-content-end rv_flag_container">
          <div class="rv_flag" [ngClass]="{'active': flagActivateRV, 'inactive': !flagActivateRV}">
            <p class="rv_flag__description">{{ flagActivateRV ? 'Activaste RV 🔥' : 'Aún no activas RV 😥' }}</p>
          </div>
        </div>
        <milab-cumplirme-card
          class="simulador_section__card"
          title="Cumplimiento RV"
          [weightedComplianceInitial]="weightedComplianceInitial"
          [weightedComplianceInitialComercial]="weightedComplianceComercialInitial"
          [weightedComplianceInitialCobranza]="weightedComplianceCobranzaInitial"
          [amountDistributed]="amountDistributed"
          (flagActivateRV)="handleFlagActivateRV($event)"
        >
        </milab-cumplirme-card>
      </div>
      <div class="col-12 custom-col-2-5 d-none d-md-block custom-padding">
        <milab-multipliers-card
          [acceleratorPercent]="acceleratorPercentInit"
          [deceleratorPercent]="deceleratorPercentInit"
          [finalAutonomy]="multipliersFinalAutonomy"
          [amount20k]="calculateBonusAmount20k"
          [dataDecelarators]="dataDecelarators"
        >
        </milab-multipliers-card>
      </div>
      <div class="col-12 custom-col-2-5 d-none d-md-block custom-padding">
        <milab-bonus-card
          class="simulador_section__card"
          title="Bonificación 0 - 20k"
          [amount]="calculateBonusAmount"
          [advance]="calculateBonusAdvance"
          [activeRv]="calculateBonusActiveRv"
          [exceeds80]="calculateBonusExceeds90"
          [amount20k]="calculateBonusAmount20k"
          [meta20k]="calculateBonusMeta20k"
        >
        </milab-bonus-card>
      </div>
      <div class="col-12 col-md-3 custom-padding">
        <milab-summary-simulator-card
          class="simulador_section__card"
          title="RV Final + Bonificación 0 - 20 K"
          description=""
          [amountDistributed]="amountDistributed"
          [factorAucencia]="factorAucencia"
          [acceleratorPercentage]="acceleratorPercentInit"
          [deceleratorPercentage]="deceleratorPercentInit"
          [finalRvInitial]="finalRvInitial"
          [meta20k]="calculateBonusMeta20k"
        >
        </milab-summary-simulator-card>
      </div>
    </div>
  </div>

  <div class="tap-pading">
    <milab-simulator-tabs
      [items]="tabItems"
      [selectedItem]="selectedTab"
      (selectedItemEmit)="handleSelectedTab($event)"
      class="taps-scroll"
    >
    </milab-simulator-tabs>
  </div>

  <milab-section-direct-variables
    *ngIf="selectedTab?.code == 'variables'"
    [managementVarCommercial]="managementVarCommercial"
    [managementVarCollectionResolution]="managementVarCollectionResolution"
    [managementVarCollectionPise]="managementVarCollectionPise"
    [listVariable]="listVariable"
    [listCollectionVariablesPise9]="listCollectionVariablesPise9"
    [listCollectionVariablesResolutions]="listCollectionVariablesResolutions"
    [weightedComplianceInitial]="weightedComplianceInitial"
    [weightedComplianceComercialInitial]="weightedComplianceComercialInitial"
    [weightedComplianceCobranzaInitial]="weightedComplianceCobranzaInitial"
    [amountDistributed]="amountDistributed"
    [pasivosMeta]="pasivosMeta"
  >
  </milab-section-direct-variables>
  <milab-section-accelerators
    *ngIf="selectedTab?.code == 'accelerators'"
    [autonomyData]="autonomyData"
  ></milab-section-accelerators>
  <milab-section-decelerators
    *ngIf="selectedTab?.code == 'decelerators'"
    [dataDecelarators]="dataDecelarators"
  ></milab-section-decelerators>
  <milab-section-bonus *ngIf="selectedTab?.code == 'bonus'" [dataBonus]="dataBonus"></milab-section-bonus>
  <milab-section-blur *ngIf="selectedTab?.code == 'blur'"></milab-section-blur>
</div>
