<aside class="adn-sidebar animate__animated animate__fadeInLeft" >
  <div class="adn-sidebar__header">
    <img src="/assets/img/adn/logo-mibanco.svg" width="97" height="48" alt="logo-Mibanco">
    <div class="adn-sidebar__close" (click)="doCloseSidebar()">
      <img src="assets/img/adn/icons/ic_equis.svg" width="12" height="12" alt="Cerrar"/>
    </div>
  </div>

  <div>
    <div class="row">
      
    </div>
  </div>

  <div class="adn-sidebar__list">
    <milab-card-list *ngFor="let card of cards; let i = index" 
    [order]="i"
    [url]="card.url"
    [icon]="card.icon" 
    [selectedIcon]="card.selectedIcon"
    [title]="card.title" 
    [isSelected]="card.isSelected"
    [selectedColor]="card.selectedColor"
    [notification]="card.notification"
    (cardClick)="onCardClick($event)">
    </milab-card-list>
  </div>

  <div class="adn-sidebar__footer">
    <div class="adn-sidebar__text d-none d-md-block">
      <h2 class="adn-sidebar__text__name">{{user.fullName}}</h2>
      <p class="adn-sidebar__text__code">Código de matrícula: {{user.codeAdn}}</p>
    </div>
    <div class="adn-sidebar__session">
      <button (click)="closeSession()" class="adn-sidebar__session_button"> <img src="/assets/img/adn/log-out.svg" alt="Cerrar sesión"> Cerrar
        sesión
      </button>
    </div>
  </div>
</aside>
<div class="shadow-content"></div>