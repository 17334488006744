import { Component, Input, OnInit } from '@angular/core';
import { AdnCentralizedDataService } from '@shared/services/adn-centralized-data.service';
import { formatDateUtil } from '@shared/utils/adn-simulator.utils';
import { ModalResetComponent } from '../modal-reset/modal-reset.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'milab-section-info',
  templateUrl: './section-info.component.html',
  styleUrls: ['./section-info.component.scss']
})
export class SectionInfoComponent implements OnInit {

  @Input() amountDistributed: string = '940';
  @Input() workingDays: string = "30";
  totalDaysInMonth!: number;
  @Input() updatedDate: string = '20241027';
  modalRef!: NgbModalRef;

  constructor(private adnCentralizedDataService:AdnCentralizedDataService,private modalService: NgbModal) { }

  ngOnInit(): void {
    this.totalDaysInMonth = this.getTotalDaysInMonth();
    this.updatedDate = formatDateUtil(this.updatedDate);
  }

  resetData(){
    this.adnCentralizedDataService.resetToInitialData();
  }

  getTotalDaysInMonth(): number {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return new Date(year, month, 0).getDate();
  }

  openModal() {
    this.modalRef = this.modalService.open(ModalResetComponent, { windowClass: 'modal-reset' });
    this.modalRef.result.then((on) => {
      if (on) {
        this.resetData();
      }
    }).catch((error) => {
      console.log(error);
    });
  }

}
