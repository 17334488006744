import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AdnAchievement, AdnData, AdnTarget, ComplianceMetrics, News, SummaryRv, NewsViewed } from '@shared/interfaces/frontend/adn';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AdnCentralizedDataService } from './adn-centralized-data.service';

@Injectable({
  providedIn: 'root',
})
export class AdnSimulatorService {
  private readonly calculatorBase = `${environment.apiBaseUrl}/api-calculator`;
  
  private weightedComplianceSubject = new BehaviorSubject<number>(0);
  weightedCompliance$ = this.weightedComplianceSubject.asObservable(); 

  private amount20kSubject = new BehaviorSubject<number>(0); 
  amount20kSubject$: Observable<number> = this.amount20kSubject.asObservable();

  private deceleratoSubject = new BehaviorSubject<number>(0); 
  deceleratoSubject$: Observable<number> = this.deceleratoSubject.asObservable();

  private achievementPassBalanceSubject = new BehaviorSubject<number>(0);
  achievementPassBalanceSubject$ = this.achievementPassBalanceSubject.asObservable();

  private metricsSubject = new BehaviorSubject<ComplianceMetrics>({
    complianceNetDisbursement: { compliance: 0, weight: 0.2 },
    complianceActiveClientStock: { compliance: 0, weight: 0.2 },
    complianceLiabilitiesCapture: { compliance: 0, weight: 0.1 },
    compliancePriceManagement: { compliance: 0, weight: 0.1 },
    compliancePise9Mora: { compliance: 0, weight: 0.2 },
    complianceResolution0Days: { compliance: 0, weight: 0.2 },
  });
  metrics$: Observable<ComplianceMetrics> = this.metricsSubject.asObservable();


  private viewedNews: NewsViewed[] = [];
  private viewedNewsSubject = new BehaviorSubject<NewsViewed[]>([]);
  viewedNews$ = this.viewedNewsSubject.asObservable();

  private summaryRvSubject = new BehaviorSubject<SummaryRv>({
    acceleratorPercentage: 0,
    deceleratorPercentage: 0,
    bonusValue: 0,
  });
  summaryRvSubject$: Observable<SummaryRv> = this.summaryRvSubject.asObservable();

  private pise9MoraSubject = new BehaviorSubject<number>(0);
  pise9MoraSubject$ = this.pise9MoraSubject.asObservable(); 

  private resolutionBalanceSubject = new BehaviorSubject<number>(0);
  resolutionBalanceSubject$ = this.resolutionBalanceSubject.asObservable(); 

  private tasaRealSubject = new BehaviorSubject<number>(0);
  tasaRealSubject$ = this.tasaRealSubject.asObservable(); 


  constructor(
    private httpClient: HttpClient,
    private centralizedData: AdnCentralizedDataService
  ) {}

  getWeightedCompliance(): number {
    return this.weightedComplianceSubject.getValue();
  }

  setWeightedCompliance(value: number) {
    this.weightedComplianceSubject.next(value);
  }

  getPise9Mora(): number {
    return this.pise9MoraSubject.getValue();
  }

  setPise9Mora(newValue: number) {
    this.pise9MoraSubject.next(newValue);
  }

  getResolutionBalance(): number {
    return this.resolutionBalanceSubject.getValue();
  }

  setResolutionBalance(newValue: number) {
    this.resolutionBalanceSubject.next(newValue);
  }
  getTasaReal(): number {
    return this.tasaRealSubject.getValue();
  }

  setTasaReal(newValue: number) {
    this.tasaRealSubject.next(newValue);
  }

  getAmount20k(): number {
    return this.amount20kSubject.getValue();
  }

  setAmount20k(newValue: number) {
    this.amount20kSubject.next(newValue);
  }

  getDeceleratoSubject(): number {
    return this.deceleratoSubject.getValue();
  }

  setDeceleratoSubject(newValue: number) {
    this.deceleratoSubject.next(newValue);
  }
  getAchievementPassBalance(): number {
    return this.achievementPassBalanceSubject.getValue();
  }

  
  setAchievementPassBalance(newValue: number) {
    this.achievementPassBalanceSubject.next(newValue);
  }


  setSummaryRv(summary: SummaryRv): void {
    this.summaryRvSubject.next(summary);
    this.calculateWeightedCompliance();
  }

  getSummaryRv(): SummaryRv {
    return this.summaryRvSubject.value;
  }

  updateAcceleratorPercentage(accelerator: number): void {
    const currentSummaryRv = this.getSummaryRv();
    currentSummaryRv.acceleratorPercentage = accelerator;
    this.setSummaryRv(currentSummaryRv);
  }

  updateBonusValue(bonus: number): void {
    const currentSummaryRv = this.getSummaryRv();
    currentSummaryRv.bonusValue = bonus;
    this.setSummaryRv(currentSummaryRv);
  }

  updateDeceleratorPercentage(decelerator: number): void {
    const currentSummaryRv = this.getSummaryRv();
    currentSummaryRv.deceleratorPercentage = decelerator;
    this.setSummaryRv(currentSummaryRv);
  }



  loadInitialData(code: string): Observable<AdnData> {
    return forkJoin({
      achievements: this.getAchievementsByCode(code),
      targets: this.getGoalsByCode(code),
    }).pipe(
      tap((data) => this.centralizedData.setInitialData(data)),
      catchError((error) => {
        console.error('Error loading initial data:', error);
        throw error;
      })
    );
  }

  getAchievementsByCode(code: string): Observable<AdnAchievement[]> {
    return this.httpClient.get<AdnAchievement[]>(`${this.calculatorBase}/api/advisors/${code}/achievements`).pipe(
      catchError((error) => {
        console.error('Error fetching achievements:', error);
        throw error;
      })
    );
  }

  getGoalsByCode(code: string): Observable<AdnTarget[]> {
    return this.httpClient.get<AdnTarget[]>(`${this.calculatorBase}/api/advisors/${code}/targets`).pipe(
      catchError((error) => {
        console.error('Error fetching goals:', error);
        throw error;
      })
    );
  }

  getTotalComplianceWeight(): Observable<number> {
    return this.weightedCompliance$;
  }

  updateAchievement(achievementId: number, changes: Partial<AdnAchievement>): void {
    this.centralizedData.currentData$.subscribe((currentData) => {
      if (!currentData) return;

      const updatedAchievements = currentData.achievements.map((achievement) =>
        achievement.achievementsAdnId === achievementId ? { ...achievement, ...changes } : achievement
      );
      this.centralizedData.updateCurrentData({ achievements: updatedAchievements });
    });
  }

  updateTarget(targetId: number, changes: Partial<AdnTarget>): void {
    this.centralizedData.currentData$.subscribe((currentData) => {
      if (!currentData) return;

      const updatedTargets = currentData.targets.map((target) =>
        target.targetsAdnId === targetId ? { ...target, ...changes } : target
      );
      this.centralizedData.updateCurrentData({ targets: updatedTargets });
    });
  }

    setMetrics(metrics: ComplianceMetrics): void {
      this.metricsSubject.next(metrics);
      this.calculateWeightedCompliance();
    }
  
    getMetrics(): ComplianceMetrics {
      return this.metricsSubject.value;
    }
  

    updateComplianceNetDisbursement(compliance: number): void {
      const weight = 0.2;
      const currentMetrics = this.getMetrics();
      currentMetrics.complianceNetDisbursement = { compliance, weight };
      this.setMetrics(currentMetrics);
    }
  
    updateComplianceActiveClientStock(compliance: number): void {
      const weight = 0.2;
      const currentMetrics = this.getMetrics();
      currentMetrics.complianceActiveClientStock = { compliance, weight };
      this.setMetrics(currentMetrics);
    }
  
    updateComplianceLiabilitiesCapture(compliance: number): void {
      const weight = 0.1;
      const currentMetrics = this.getMetrics();
      currentMetrics.complianceLiabilitiesCapture = { compliance, weight};
      this.setMetrics(currentMetrics);
    }
  
    updateCompliancePriceManagement(compliance: number): void {
      const weight = 0.1;
      const currentMetrics = this.getMetrics();
      currentMetrics.compliancePriceManagement = { compliance, weight };
      this.setMetrics(currentMetrics);
    }
  
    updateCompliancePise9Mora(compliance: number): void {
      const weight = 0.2;
      const currentMetrics = this.getMetrics();
      currentMetrics.compliancePise9Mora = { compliance, weight };
      this.setMetrics(currentMetrics);
    }
  
    updateComplianceResolution0Days(compliance: number): void {
      const weight = 0.2;
      const currentMetrics = this.getMetrics();
      currentMetrics.complianceResolution0Days = { compliance, weight };
      this.setMetrics(currentMetrics);
    }

    calculateWeightedCompliance(): void {
      const metrics = this.getMetrics();
    
      const weightedCompliance =
        (metrics.complianceNetDisbursement.compliance * metrics.complianceNetDisbursement.weight) +
        (metrics.complianceActiveClientStock.compliance * metrics.complianceActiveClientStock.weight) +
        (metrics.complianceLiabilitiesCapture.compliance * metrics.complianceLiabilitiesCapture.weight) +
        (metrics.compliancePriceManagement.compliance * metrics.compliancePriceManagement.weight) +
        (metrics.compliancePise9Mora.compliance * metrics.compliancePise9Mora.weight) +
        (metrics.complianceResolution0Days.compliance * metrics.complianceResolution0Days.weight);
    
      this.setWeightedCompliance(weightedCompliance);
    }

    getNews(userId: number): Observable<News[]> {
      return this.httpClient.get<News[]>(`${this.calculatorBase}/news/all/${userId}`);
    }
  
    updateView(userId: number, newsId: number): Observable<void> {
      return this.httpClient.put<void>(`${this.calculatorBase}/news/update-view?userId=${userId}&newsId=${newsId}`,{});
    }

    markAsViewed(newsId: number): void {
      const updatedList = this.viewedNews.map(news =>
        news.newsId === newsId ? { ...news, viewed: true } : news
      );
    this.viewedNews = updatedList;
    this.viewedNewsSubject.next(updatedList);
    }
  
    getViewedNews(): NewsViewed[] {
      return this.viewedNews;
    }
    setInitViewedNews(viewedNews: NewsViewed[]):void {
      this.viewedNewsSubject.next([...viewedNews]);
    }
}