import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';

@Component({
  selector: 'milab-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {
  @Input() order: number = 0;
  @Input() url: string = '' ;
  @Input() icon: string = '';
  @Input() selectedIcon: string = '';
  @Input() title: string = '';
  @Input() isSelected: boolean = false;
  @Input() selectedColor: string = '#434A54';
  @Input() notification: boolean = false;
  @Output() cardClick = new EventEmitter<any>();
  loader: boolean = true;
  isMobile: boolean = window.innerWidth < 768;
  constructor(private adnSimulatorService:AdnSimulatorService) {}
  ngOnInit(): void {
    this.onResize();
    this.obsNotification();
    this.simulateLoading();

  }
  obsNotification() {
    this.adnSimulatorService.viewedNews$.subscribe(updatedNews => {
      if (this.url === 'noticias') {
        this.notification = updatedNews.some(news => !news.viewed);
      }
    });
  }

  onCardClick(order: number, url: string): void {
    this.cardClick.emit({ order: order, url: url });
  }
  simulateLoading() {
    if (!this.isMobile) {
      setTimeout(() => {
        this.loader = false;
      }, 1000);
    } else {
      this.loader = false;
    }
  }

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth < 768;
  }
}
