import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INCREASE } from '@shared/constants/adn-simulator.constants';
import { ComplianceMetrics } from '@shared/interfaces/frontend/adn';
import { AdnSimulatorService } from '@shared/services/adn-simulator.service';
import {
  calculateDirectRemuneration,
  calculateWeightedCompliance,
  convertToNumeric,
  formatNumberWithCommasNoDecimals,
  formatPercentage,
  formatPercentageV2,
} from '@shared/utils/adn-simulator.utils';

@Component({
  selector: 'milab-cumplirme-card',
  templateUrl: './cumplirme-card.component.html',
  styleUrls: ['./cumplirme-card.component.scss'],
})
export class CumplirmeCardComponent implements OnInit {
  @Input() title!: string;
  @Input() weightedComplianceInitial!: number;
  @Input() weightedComplianceInitialComercial!: number;
  @Input() weightedComplianceInitialCobranza!: number;
  @Input() amountDistributed: number = 0;
  @Output() flagActivateRV = new EventEmitter<boolean>();

  increaseCompliance: string = '';
  increaseComplianceComercial: string = '';
  increaseComplianceCobranza: string = '';
  increaseDistributed: string = '';
  increaseDistributedComercial: string = '';
  increaseDistributedCobranza: string = '';
  complianceColor: string = INCREASE.colorIncrement;
  complianceColorComercial: string = INCREASE.colorIncrement;
  complianceColorCobranza: string = INCREASE.colorIncrement;
  distributedColor: string = INCREASE.colorIncrement;
  distributedColorComercial: string = INCREASE.colorIncrement;
  distributedColorCobranza: string = INCREASE.colorIncrement;
  iconCompliance: string = INCREASE.iconArrowUp;
  iconComplianceComercial: string = INCREASE.iconArrowUp;
  iconComplianceCobranza: string = INCREASE.iconArrowUp;
  iconDistributede: string = INCREASE.iconArrowUp;
  iconDistributedeComercial: string = INCREASE.iconArrowUp;
  iconDistributedeCobranza: string = INCREASE.iconArrowUp;

  directRemunerationValue: number = 0;
  directRemunerationValueComercial: number = 0;
  directRemunerationValueCobranza: number = 0;
  directRemunerationInitial: number = 0;
  directRemunerationInitialComercial: number = 0;
  directRemunerationInitialCobranza: number = 0;
  weightedCompliance: number = 0;
  weightedComplianceComercial: number = 0;
  weightedComplianceCobranza: number = 0;
  imgComplianceWeight: string = '/assets/icons/adn/x-close.svg';
  imgComplianceWeightComercial: string = '/assets/icons/adn/x-close.svg';
  imgComplianceWeightCobranza: string = '/assets/icons/adn/x-close.svg';

  constructor(private adnSimulatorService: AdnSimulatorService) {
    this.adnSimulatorService.metrics$.subscribe((value) => {
      const ponderado = this.calculateWeightedComplianceSync(value);
      const ponderadoComercial = this.calculateWeightComercial(value);
      const ponderadoCobranza = this.calculateWeightCobranza(value);

      calculateWeightedCompliance(ponderado)
        ? (this.imgComplianceWeight = '/assets/icons/adn/check-circle.svg')
        : (this.imgComplianceWeight = '/assets/icons/adn/x-close.svg');
      calculateWeightedCompliance(ponderadoComercial)
        ? (this.imgComplianceWeightComercial = '/assets/icons/adn/check-circle.svg')
        : (this.imgComplianceWeightComercial = '/assets/icons/adn/x-close.svg');
      calculateWeightedCompliance(ponderadoCobranza)
        ? (this.imgComplianceWeightCobranza = '/assets/icons/adn/check-circle.svg')
        : (this.imgComplianceWeightCobranza = '/assets/icons/adn/x-close.svg');
      this.updateDirectRemunerationValue(ponderado);
      this.updateDirectComercialRemunerationValue(ponderadoComercial);
      this.updateDirectCobranzaRemunerationValue(ponderadoCobranza);
    });
  }

  ngOnInit(): void {
    this.calculateInit();
  }
  calculateInit() {
    const adjustedValue = calculateWeightedCompliance(this.weightedComplianceInitial);
    const adjustedValueComercial = calculateWeightedCompliance(this.weightedComplianceInitialComercial);
    const adjustedValueCobranza = calculateWeightedCompliance(this.weightedComplianceInitialCobranza);
    this.directRemunerationInitial = calculateDirectRemuneration(
      this.amountDistributed,
      this.weightedComplianceInitial,
      adjustedValue
    );
    this.directRemunerationInitialComercial = calculateDirectRemuneration(
      this.amountDistributed,
      this.weightedComplianceInitialComercial,
      adjustedValueComercial
    );
    this.directRemunerationInitialCobranza = calculateDirectRemuneration(
      this.amountDistributed,
      this.weightedComplianceInitialCobranza,
      adjustedValueCobranza
    );
  }

  private updateDirectRemunerationValue(weightedCompliance: number): void {
    this.weightedCompliance = convertToNumeric(formatPercentageV2(weightedCompliance));
    this.calculeIncremented(weightedCompliance);
    const adjustedValue = calculateWeightedCompliance(weightedCompliance);
    this.directRemunerationValue = calculateDirectRemuneration(
      this.amountDistributed,
      weightedCompliance,
      adjustedValue
    );
    this.calculeIncrementedRemuneration(this.directRemunerationValue);
  }

  private updateDirectComercialRemunerationValue(weightedCompliance: number): void {
    this.weightedComplianceComercial = convertToNumeric(formatPercentageV2(weightedCompliance));
    this.calculeIncrementedComercial(weightedCompliance);
    const adjustedValue = calculateWeightedCompliance(weightedCompliance);
    this.directRemunerationValueComercial = calculateDirectRemuneration(
      this.amountDistributed,
      weightedCompliance,
      adjustedValue
    );
    this.validateFlagActivateRV();
    this.calculeIncrementedRemunerationComercial(this.directRemunerationValueComercial);
  }

  private updateDirectCobranzaRemunerationValue(weightedCompliance: number): void {
    this.weightedComplianceCobranza = convertToNumeric(formatPercentageV2(weightedCompliance));
    this.calculeIncrementedCobranza(weightedCompliance);
    const adjustedValue = calculateWeightedCompliance(weightedCompliance);
    this.directRemunerationValueCobranza = calculateDirectRemuneration(
      this.amountDistributed,
      weightedCompliance,
      adjustedValue
    );
    this.validateFlagActivateRV();
    this.calculeIncrementedRemunerationCobranza(this.directRemunerationValueCobranza);
  }

  calculeIncremented(weightedCompliance: number): void {
    const increaseTemp = weightedCompliance - this.weightedComplianceInitial;
    if (increaseTemp > 0) {
      this.increaseCompliance = formatPercentage(increaseTemp);
      this.complianceColor = INCREASE.colorIncrement;
      this.iconCompliance = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseCompliance = formatPercentage(increaseTemp);
      this.complianceColor = INCREASE.colorDecrement;
      this.iconCompliance = INCREASE.iconArrowDown;
    } else {
      this.increaseCompliance = '';
    }
  }
  calculeIncrementedComercial(weightedCompliance: number): void {
    const increaseTemp = weightedCompliance - this.weightedComplianceInitialComercial;
    if (increaseTemp > 0) {
      this.increaseComplianceComercial = formatPercentage(increaseTemp);
      this.complianceColorComercial = INCREASE.colorIncrement;
      this.iconComplianceComercial = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseComplianceComercial = formatPercentage(increaseTemp);
      this.complianceColorComercial = INCREASE.colorDecrement;
      this.iconComplianceComercial = INCREASE.iconArrowDown;
    } else {
      this.increaseComplianceComercial = '';
    }
  }
  calculeIncrementedCobranza(weightedCompliance: number): void {
    const increaseTemp = weightedCompliance - this.weightedComplianceInitialCobranza;
    if (increaseTemp > 0) {
      this.increaseComplianceCobranza = formatPercentage(increaseTemp);
      this.complianceColorCobranza = INCREASE.colorIncrement;
      this.iconComplianceCobranza = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseComplianceCobranza = formatPercentage(increaseTemp);
      this.complianceColorCobranza = INCREASE.colorDecrement;
      this.iconComplianceCobranza = INCREASE.iconArrowDown;
    } else {
      this.increaseComplianceCobranza = '';
    }
  }

  calculeIncrementedRemuneration(remunerationValue: number): void {
    const increaseTemp = remunerationValue - this.directRemunerationInitial;
    const increaseTempTwoDecimals = parseFloat(increaseTemp.toFixed(2));
    if (increaseTemp > 0) {
      this.increaseDistributed = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColor = INCREASE.colorIncrement;
      this.iconDistributede = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseDistributed = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColor = INCREASE.colorDecrement;
      this.iconDistributede = INCREASE.iconArrowDown;
    } else {
      this.increaseDistributed = '';
    }
  }

  calculeIncrementedRemunerationComercial(remunerationValue: number): void {
    const increaseTemp = remunerationValue - this.directRemunerationInitialComercial;
    const increaseTempTwoDecimals = parseFloat(increaseTemp.toFixed(2));
    if (increaseTemp > 0) {
      this.increaseDistributedComercial = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColorComercial = INCREASE.colorIncrement;
      this.iconDistributedeComercial = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseDistributedComercial = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColorComercial = INCREASE.colorDecrement;
      this.iconDistributedeComercial = INCREASE.iconArrowDown;
    } else {
      this.increaseDistributedComercial = '';
    }
  }

  calculeIncrementedRemunerationCobranza(remunerationValue: number): void {
    const increaseTemp = remunerationValue - this.directRemunerationInitialCobranza;
    const increaseTempTwoDecimals = parseFloat(increaseTemp.toFixed(2));
    if (increaseTemp > 0) {
      this.increaseDistributedCobranza = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColorCobranza = INCREASE.colorIncrement;
      this.iconDistributedeCobranza = INCREASE.iconArrowUp;
    } else if (increaseTemp < 0) {
      this.increaseDistributedCobranza = 'S/ ' + formatNumberWithCommasNoDecimals(increaseTempTwoDecimals);
      this.distributedColorCobranza = INCREASE.colorDecrement;
      this.iconDistributedeCobranza = INCREASE.iconArrowDown;
    } else {
      this.increaseDistributedCobranza = '';
    }
  }

  calculateWeightedComplianceSync(metrics: ComplianceMetrics): number {
    return (
      metrics.complianceNetDisbursement.compliance * metrics.complianceNetDisbursement.weight +
      metrics.complianceActiveClientStock.compliance * metrics.complianceActiveClientStock.weight +
      metrics.complianceLiabilitiesCapture.compliance * metrics.complianceLiabilitiesCapture.weight +
      metrics.compliancePriceManagement.compliance * metrics.compliancePriceManagement.weight +
      metrics.compliancePise9Mora.compliance * metrics.compliancePise9Mora.weight +
      metrics.complianceResolution0Days.compliance * metrics.complianceResolution0Days.weight
    );
  }

  calculateWeightComercial(metrics: ComplianceMetrics): number {
    return (
      metrics.complianceNetDisbursement.compliance * metrics.complianceNetDisbursement.weight +
      metrics.complianceActiveClientStock.compliance * metrics.complianceActiveClientStock.weight +
      metrics.complianceLiabilitiesCapture.compliance * metrics.complianceLiabilitiesCapture.weight +
      metrics.compliancePriceManagement.compliance * metrics.compliancePriceManagement.weight
    );
  }

  calculateWeightCobranza(metrics: ComplianceMetrics): number {
    return (
      metrics.compliancePise9Mora.compliance * metrics.compliancePise9Mora.weight +
      metrics.complianceResolution0Days.compliance * metrics.complianceResolution0Days.weight
    );
  }

  validateFlagActivateRV(): void {
    calculateWeightedCompliance(this.weightedComplianceComercial) && calculateWeightedCompliance(this.weightedComplianceCobranza)
      ? this.flagActivateRV.emit(true)
      : this.flagActivateRV.emit(false);
  }
}
