<div class="sk-container" *ngIf="!loader">

  <div class="sk-container__flex">
  <ngx-skeleton-loader *ngIf="!loader" 
    [appearance]="'line'" 
    count="1" 
    animation="pulse"
    [theme]="{ width: isMobile ? '300px' : '506px', height: '32px', 'border-radius': '8px', 'background-color': '#E6E9ED' }">
  </ngx-skeleton-loader>
  </div>
  <div class="sk-container__flex mb-3">
    <ngx-skeleton-loader *ngIf="!loader" [appearance]="'circle'" count="1" animation="pulse"
    [theme]="{ width: '16px', height: '16px', 'background-color': '#E6E9ED'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader *ngIf="!loader" [appearance]="'line'" count="1" animation="pulse"
    [theme]="{ width: '202px', height: '20px', 'background-color': '#E6E9ED'}"></ngx-skeleton-loader>
  </div>

  <ngx-skeleton-loader 
   *ngIf="!loader" 
   [appearance]="'line'" 
   count="2" 
   animation="pulse"
   [theme]="{
    width: isMobile ? '100%' : '506px',
    height: isMobile ? '266px' : '400px',
    'border-radius': '6px',
    'background-color': '#E6E9ED',
    'margin-left': isMobile ? '0px' : '10px'
   }">
</ngx-skeleton-loader>

<ngx-skeleton-loader 
*ngIf="!loader" 
[appearance]="'line'" 
count="2" 
animation="pulse"
[theme]="{
 width: isMobile ? '100%' : '506px',
 height: isMobile ? '266px' : '400px',
 'border-radius': '6px',
 'background-color': '#E6E9ED',
 'margin-left': isMobile ? '0px' : '10px'
}">
</ngx-skeleton-loader>


</div>

<div class="news-container" *ngIf="loader">
    <div class="t-24-700">Modelo de RV 2025: Conoce los nuevos cambios de tu RV</div>
    <div class="news-container__date">
      <img src="/assets/img/adn/icons/clock-rewind.svg" alt="tiempo">
      <div class="t-14-400 font-lato bg-434A54">Actualizado al {{updatedDate}}</div>
    </div>

    <div class="news-container__section">
      <div *ngFor="let news of newsList" class="news-card">
        <div class="img-content"><img [src]="news.imageNews" alt="news"></div>
        <div class="t-18-700 color-dark mt-4 news-title" (click)=openModal(news)>{{news.title}}</div>
        <button (click)=openModal(news) class="mt-4">Leer más</button>
      </div>
    </div>
</div>
